/* You can add global styles to this file, and also import other style files */
@import url('@scania/tegel/dist/tegel/tegel.css');

.tds-card {
    background-color: #f9f9f9;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.content-container {
    display: flex;
    justify-content: center;
}

.success-snackbar {
    background-color: #1DAB8B;
    color: white;
}

.error-snackbar {
    background-color: #D6001C;
    color: white;
}

.info-snackbar {
    background-color: #2B70D3;
    color: white;
}

.warning-snackbar {
    background-color: #F1C21B;
    color: white;
}

.success-snackbar {
    --mdc-snackbar-container-color: #1DAB8B !important;
    --mdc-snackbar-supporting-text-color: #FFFFFF !important;
    --mat-snack-bar-button-color: #FFFFFF !important;
}

.error-snackbar {
    --mdc-snackbar-container-color: #D6001C !important;
    --mdc-snackbar-supporting-text-color: #FFFFFF !important;
    --mat-snack-bar-button-color: #FFFFFF !important;
}

.info-snackbar {
    --mdc-snackbar-container-color: #2B70D3 !important;
    --mdc-snackbar-supporting-text-color: #FFFFFF !important;
    --mat-snack-bar-button-color: #FFFFFF !important;
}

.warning-snackbar {
    --mdc-snackbar-container-color: #F1C21B !important;
    --mdc-snackbar-supporting-text-color: #FFFFFF !important;
    --mat-snack-bar-button-color: #FFFFFF !important;
}

.card-no-max-width {
    max-width: none !important;
}

.card-min-content {
    max-width: min-content !important;
}

.card-with-scroll{
   overflow-y: scroll;
   max-height: 400px;
}

.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-selected-checkmark-color: white;
    --mdc-checkbox-selected-focus-icon-color: #041E42;
    --mdc-checkbox-selected-hover-icon-color: #041E42;
    --mdc-checkbox-selected-icon-color: #041E42;
    --mdc-checkbox-selected-pressed-icon-color: #041E42;
    --mdc-checkbox-unselected-focus-icon-color: #041E42;
    --mdc-checkbox-unselected-hover-icon-color: #041E42;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #041E42;
    --mdc-checkbox-selected-hover-state-layer-color: #041E42;
    --mdc-checkbox-selected-pressed-state-layer-color: #041E42;
    --mdc-checkbox-unselected-focus-state-layer-color: black;
    --mdc-checkbox-unselected-hover-state-layer-color: black;
    --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.tds-u-justify-self-center{
    justify-self: center;
}

.tds-u-justify-self-end{
    justify-self: flex-end;
}

.tds-u-justify-self-start{
    justify-self: flex-start;
}